export const imageFileTypeRegex = /.*\.(bmp|gif|jpeg|jpg|png|psd)(\?.*)?$/i
export const videoFileTypeRegex = /.*\.(avi|dat|m4v|mod|mov|mp4|mpeg|mpeg4|mpg|ogm|ogv|qt|wmv|gif)(\?.*)?$/i
export const audioFileTypeRegex = /.*\.(mp3|m4a|wav)(\?.*)?$/i

export const isImage = (filename) => imageFileTypeRegex.test(filename)
export const isVideo = (filename) => videoFileTypeRegex.test(filename)
export const isAudio = (filename) => audioFileTypeRegex.test(filename);

export const doesImageExist = (url, timeout) =>

  new Promise(function (resolve, reject) {
    const promiseTimeout = timeout || 5000
    let timer
    const img = new Image()
    img.onerror = img.onabort = function () {
      clearTimeout(timer)
      // console.log(url, false, 'error')
      reject(url)
    }
    img.onload = function () {
      clearTimeout(timer)
      // console.log(url, true)
      resolve(url)
    }
    timer = setTimeout(function () {
    // reset .src to invalid URL so it stops previous
    // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg'
      // console.log(url, false, 'timeout')
      reject(url)
    }, promiseTimeout)
    img.src = url
  })
